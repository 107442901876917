import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index'

const routes = [
    {
        path: '/',
        redirect: '/landing-page'
    },
    {
        path: '/landing-page',
        name: 'LandingPage',
        component: () => import("../views/LandingPage"),
    },
    {
        path: '/post-opt-in',
        name: 'PostOptIn',
        component: () => import("../views/PostOptIn"),
    },
    {
        path: '/wifi',
        name: 'OnWifiUser',
        component: () => import("../views/OnWifiUser"),
        meta: { hideWinDailyBanner: true },
    },
    {
        path: '/insufficient-funds',
        name: 'InsufficientFundsUser',
        component: () => import("../views/InsufficientFundsUser"),
    },
    {
        path: '/declined',
        name: 'DeclinedUser',
        redirect: '/'
    },
    {
        path: '/timeout',
        name: 'TimeoutUser',
        component: () => import("../views/TimeoutUser"),
    },
    {
        path: '/content-block',
        name: 'ContentBlockUser',
        component: () => import("../views/ContentBlockUser"),
    },
    {
        path: '/evina-redirect',
        name: 'EvinaRedirect',
        component: () => import("../views/EvinaRedirect"),
    },
    {
        path: '/error-page',
        name: 'ErrorPage',
        component: () => import("../views/ErrorPage"),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: '/landing-page'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
});

router.beforeEach(() => {
  if (!store.state.acquisitionConfig) {
      const urlParams = new URLSearchParams(window.location.search);
      const campaignId = urlParams.get('campaignId') || urlParams.get('cid') || 0;
      const acquisitionChannel = urlParams.get('acquisitionChannel') || 'Organic-Web';
      const revenuePartnerId = urlParams.get('revenuePartnerId') || 27;
      const msisdnToken = urlParams.get('token') === 'undefined' || urlParams.get('token') === 'null' ? undefined : urlParams.get('token');
      const publisherId = urlParams.get('publisherId');
      const trackerId = urlParams.get('trackerId') || urlParams.get('click_id');
      store.commit('setAcquisitionConfig', {campaignId, acquisitionChannel, revenuePartnerId, msisdnToken, publisherId, trackerId});
  }
})

export default router;
