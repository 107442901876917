<template>
  <div id="app">
    <div class="d-flex row justify-content-center content-body">
      <router-view class=""></router-view>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState(['showWinDailyBanner'])
  },
  methods: {
    ...mapMutations(['setShowWinDailyBanner'])
  },
}
</script>

<style>

#app {
  min-height: 100%;
}

.content-body {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: v-bind('containerScroll')
}
</style>
