import { createStore } from 'vuex'

export default createStore({
  state: {
    brandConfig: undefined,
    showWinDailyBanner: true,
    acquisitionConfig: undefined
  },
  mutations: {
    setShowWinDailyBanner(state, showWinDailyBanner) {
      state.showWinDailyBanner = showWinDailyBanner
    },
    setAcquisitionConfig(state, acquisitionConfig) {
      state.acquisitionConfig = acquisitionConfig
    },
  },
  actions: {
  },
  modules: {
  }
})
